import React, { Component } from 'react'
import './Corefit.css'
import './CorefitResponsive.css'

class Corefit extends Component {
  render () {
    return (
      <div id='corefit'>
        <div className='corefit-block'>
          <div className='core-logo'>
            <img class='corefit-logo' src='../../assets/images/corefit-logo.png' alt='Corefit_Logo' />
          </div>
          <div className='corefit-title'>Coming soon...</div>
          <div className='corefit-desc'>Your core is your powerhouse and you must build it. No matter your fitness level, your goals or your age, we are here to help you do just that! CorefitPlus is India’s first workout center that offers Pilates and Yoga, a perfect combination regime for a strong and healthy body.</div>
        </div>
      </div>
    )
  }
}
export default Corefit
