import React, { Component } from 'react'
import { Link, withRouter } from 'react-router-dom'
import Slide from 'react-reveal/Slide'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import Fade from 'react-reveal/Fade'
import './Header.css'
import './HeaderResponsive.css'
import 'react-toastify/dist/ReactToastify.css'

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isMenuExpanded: true,
      isMenuClicked: true,
      isMenuItemAnimated: false,
      menuItems: '',
      activeHdrMenu: '',
      currentPage: window.location.pathname,
      aromaLogo: '../../assets/images/aromathai_logo.svg',
      show: true
    }
    this.handleScroll = this.handleScroll.bind(this)
    this.handleHdrMenu = this.handleHdrMenu.bind(this)
  }

  handleHdrMenu(obj) { this.setState({ activeHdrMenu: obj }) }

  externalLinks(link) {
    var win = window.open(link, '_blank')
    win.focus() // opens the link in new tab
  }

  componentDidMount() {
    let { currentPage } = this.state
    if (currentPage === '/') this.setState({ activeHdrMenu: 'HO' })
    else if (currentPage === '/aboutus') this.setState({ activeHdrMenu: 'AU' })
    else if (currentPage.split('/')[1] === 'ourservices') this.setState({ activeHdrMenu: 'OS' })
    else if (currentPage === '/giftvoucher') this.setState({ activeHdrMenu: 'GV' })
    else if (currentPage === '/blog') this.setState({ activeHdrMenu: 'BG' })
    else if (currentPage === '/membership') this.setState({ activeHdrMenu: 'ME' })
    window.addEventListener('scroll', this.handleScroll)
  }

  externalLinks(link) {
    const win = window.open(link, '_blank')
    if (win)
      win.focus() // opens the link in new tab
  }

  handlePageNavigation(obj) {
    var pathName = window.localStorage.getItem('pathName')

    if (pathName !== obj) {
      window.localStorage.setItem('pathName', obj)
      this.props.history.push('/')
      if (obj === 'AU') this.props.history.push('aboutus')
      else if (obj === 'OS') this.props.history.push('ourservices')
      else if (obj === 'GV') this.props.history.push('giftvoucher')
      else if (obj === 'BG') this.props.history.push('blog')
      else if (obj === 'ME') this.props.history.push('membership')
      clearAllBodyScrollLocks()
      this.handleMenu()
    } else {
      const targetElement = document.querySelector('body')
      disableBodyScroll(targetElement)
    }
  }

  handleScroll() {
    var headRow = document.getElementById('headerRow')
    var header = document.getElementById('header')
    if (window.location.pathname === '/aboutus' || window.location.pathname === '/blog') {
      if (headRow.classList.contains('min-header')) {
        headRow.classList.remove('min-header')
        headRow.classList.add('header-row')
        header.style.transform = 'translateY(0px)'
        this.setState({ aromaLogo: '../../assets/images/aromathai_logo.svg' })
      }
    } else {
      let scrollTop = Math.floor(window.pageYOffset)
      let scrollHeight = document.body.scrollHeight
      let scrollPer = Math.floor(scrollTop / scrollHeight * 100) // calculating percentage while scrolling
      if (scrollPer === 0) {
        headRow.classList.remove('min-header')
        headRow.classList.add('header-row')
        header.style.transform = 'translateY(0px)'
        this.setState({ aromaLogo: '../../assets/images/aromathai_logo.svg' })
      }

      // header minimizing while scolling
      if ((scrollPer > 1) && (scrollPer <= 10)) header.style.transform = 'translateY(-100%)'

      if (scrollPer > 18) {
        headRow.classList.remove('header-row')
        header.style.transform = 'translateY(0px)'
        this.setState({ aromaLogo: '../../assets/images/aromathai_logo.svg' })
        headRow.classList.add('min-header')
      }
    }
  }

  handleMenu() {
    if (this.state.isMenuClicked) {
      this.setState({ isMenuItemAnimated: !this.state.isMenuItemAnimated })
      this.setState({ isMenuClicked: false })
      var line1 = document.getElementById('line1')
      var line2 = document.getElementById('line2')
      var line3 = document.getElementById('line3')
      var menucontent = this.refs.menuContainer
      const targetElement = document.querySelector('body')
      disableBodyScroll(targetElement)
      if (this.state.isMenuExpanded === true) {
        this.setState({ isMenuExpanded: false })
        line1.style.transform = 'rotate(-45deg) translate(-5px,5px)'
        line3.style.transform = 'rotate(45deg) translate(-7px,-8px)'
        line2.style.visibility = 'hidden'
        line2.style.transition = 'auto'
        menucontent.style.transform = 'translateY(0%)'
        menucontent.style.transition = '1s'
      } else {
        this.setState({ isMenuExpanded: true })
        line1.style.transform = 'rotate(0deg) translate(0px,0px)'
        line2.style.visibility = 'visible'
        line3.style.transform = 'rotate(0deg) translate(0px,0px)'
        menucontent.style.transform = 'translateY(-100%)'
        menucontent.style.transition = '1s'
        clearAllBodyScrollLocks()
      }
      setTimeout(this.setState({ isMenuClicked: true }))
    }
  }

  render() {
    const { activeHdrMenu } = this.state

    return (
      <div id='header'>
        {/* Header row */}
        <Fade bottom distance='30px'>
          <div className='header-row' id='headerRow'>
            <div className='header-left'>
              <Link to='/' data-testid='/'><img className='aroma-logo' src={this.state.aromaLogo} alt='AromaThai_Logo' /></Link></div>
            <div className='header-right'>
              <ul className='menu-row-block' ref='menu-row-block'>
                <li className={activeHdrMenu === 'HO' ? 'list-active' : 'list-inactive'} ><Link data-testid='testhomenu' onClick={() => this.handleHdrMenu('HO')} className={activeHdrMenu === 'HO' ? 'menu-active' : ''} to='/'>Home</Link></li>
                <li className={activeHdrMenu === 'AU' ? 'list-active' : 'list-inactive'} ><Link data-testid='testaumenu' onClick={() => this.handleHdrMenu('AU')} className={activeHdrMenu === 'AU' ? 'menu-active' : ''} to='/aboutus'>About Us</Link></li>
                <li className={activeHdrMenu === 'OS' ? 'list-active' : 'list-inactive'} ><Link data-testid='testosmenu' onClick={() => this.handleHdrMenu('OS')} className={activeHdrMenu === 'OS' ? 'menu-active' : ''} to='/ourservices'>Our Services</Link></li>
                <li className={activeHdrMenu === 'GV' ? 'list-active' : 'list-inactive'} ><Link data-testid='testgvmenu' onClick={() => this.handleHdrMenu('GV')} className={activeHdrMenu === 'GV' ? 'menu-active' : ''} to='/giftvoucher'>Gift Voucher</Link></li>
                {/* <li className={activeHdrMenu === 'BG' ? 'list-active' : 'list-inactive'} ><Link onClick={() => this.handleHdrMenu('BG')} className={activeHdrMenu === 'BG' ? 'menu-active' : ''} to='/blog'>Blog</Link></li> */}
                <li className={activeHdrMenu === 'ME' ? 'list-active' : 'list-inactive'} ><Link data-testid='testmemenu' onClick={() => this.handleHdrMenu('ME')} className={activeHdrMenu === 'ME' ? 'menu-active' : ''} to='/membership'>Membership</Link></li>
                <li className={activeHdrMenu === 'CO' ? 'list-active' : 'list-inactive'} data-testid='testcomenu' onClick={() => this.externalLinks('https://www.corefitplus.com/')}><span className={activeHdrMenu === 'CO' ? 'menu-active' : ''} >Corefit Plus</span></li>

              </ul>
              <div className='menu-logo' data-testid='testmenulogo' onClick={() => this.handleMenu()}>
                <div className='menuic'>
                  <div id='line1' className='line1' />
                  <div id='line2' className='line2' />
                  <div id='line3' className='line3' />
                </div>
              </div>
            </div>
          </div>
        </Fade>

        {/* top->bottom-> slider menu */}
        <div className='menu-container' ref='menuContainer'>
          <div className='menu-content'>
            <div className='menu-item' data-testid='home' onClick={() => this.handlePageNavigation('/')}>
              <Slide top delay={200} when={this.state.isMenuItemAnimated}>
                <div className='menu-title'>home</div>
                <div className='divider' />
              </Slide>
            </div>
            <div className='menu-item' data-testid='aboutus' onClick={() => this.handlePageNavigation('AU')} >
              <Slide top delay={400} when={this.state.isMenuItemAnimated}>
                <div className='menu-title'>About us</div>
                <div className='divider' />
              </Slide>
            </div>
            <div className='menu-item' data-testid='otherservices' onClick={() => this.handlePageNavigation('OS')}>
              <Slide top delay={600} when={this.state.isMenuItemAnimated}>
                <div className='menu-title'>Our Services</div>
                <div className='divider' />
              </Slide>
            </div>
            <div className='menu-item' data-testid='giftvoucher' onClick={() => this.handlePageNavigation('GV')}>
              <Slide top delay={800} when={this.state.isMenuItemAnimated}>
                <div className='menu-title'>Gift Voucher</div>
                <div className='divider' />
              </Slide>
            </div>
            {/* <div className='menu-item' onClick={() => this.handlePageNavigation('BG')}>
              <Slide top delay={800} when={this.state.isMenuItemAnimated}>
                <div className='menu-title'>Blog</div>
                <div className='divider' />
              </Slide>
            </div> */}
            <div className='menu-item' data-testid='membership' onClick={() => this.handlePageNavigation('ME')}>
              <Slide top delay={1000} when={this.state.isMenuItemAnimated}>
                <div className='menu-title'>Membership</div>
                <div className='divider' />
              </Slide>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default withRouter(Header)
