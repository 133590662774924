import React, { Component } from 'react'
// css
import './BannerButton.css'

class BannerButton extends Component {
  
  componentDidMount () {
    // console.log('btn', this.props)
  }

  render () {
    return (
      <div className='banner-btn-outer'><div className='banner-btn-inner' data-testid='bannerbutton' onClick={this.props.btnClick}>{this.props.btnText}</div></div>
    )
  }
}

export default BannerButton
