import React, {useEffect, Suspense } from 'react'
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import ReactGA from 'react-ga';

// component1
// import Footer from './components/Footer/Footer'
import Home from './components/Home/Home'
import AboutUs from './components/AboutUs/AboutUs'
import OurServices from './components/OurServices/OurServices'
import GiftVoucher from './components/GiftVoucher/GiftVoucher'
import MemberShip from './components/MemberShip/MemberShip'
import CCAvenue from './components/CCAvenue/CCAvenue'
import CCAVResponseHandler from './components/CCAVResponseHandler/CCAVResponseHandler'
import CCAVErrorHandler from './components/CCAVErrorHandler/CCAVErrorHandler'
import Notfound from './NotFound/NotFound.js'
import Awaited from './components/Awaited/Awaited'
import TermsAndService from './components/TermsandService/termsandservice'
import PrivacyPolicy from './components/PrivacyPolicy/privacypolicy'
import BlogLanding from './components/BlogLanding/BlogLanding'
import ArticleDetails from './components/ArticleDetails/ArticleDetails'

const Routes = props => {
  useEffect(() => {
    ReactGA.initialize('UA-192544306-3')
    ReactGA.pageview(window.location.pathname + window.location.search);
  },[])

  return (
    <Router>
      <Suspense fallback={<div>Loading...</div>}>
        <Switch>
          <Route exact path='/' component={Home} />
          <Route exact path='/aboutus' component={AboutUs} />
          <Route exact path='/ourservices' component={OurServices} />
          <Route exact path='/ourservices/:serviceName' component={OurServices} />
          <Route exact path='/giftvoucher' component={GiftVoucher} />
          <Route exact path='/membership' component={MemberShip} />
          <Route exact path='/awaited' component={Awaited} />
          <Route exact path='/ccavenue' component={CCAvenue} />
          <Route exact path='/ccavResponseHandler' component={CCAVResponseHandler} />
          <Route exact path='/ccavErrorHandler' component={CCAVErrorHandler} /> 
          <Route exact path='/termsandservice' component={TermsAndService} /> 
          <Route exact path="/privacypolicy"  component={PrivacyPolicy}   /> 
          <Route exact path="/blog" component={BlogLanding} /> 
          <Route exact path='/blog/article/:articleid' component={ArticleDetails} />
          <Route path='*' exact component={Notfound} />
        </Switch>
      </Suspense>
    </Router>
  )
}

export default Routes
