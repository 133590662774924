import React, { Component } from 'react'
import Fade from 'react-reveal/Fade'
// import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import Booking from '../Booking/Booking'
import Membership from '../Membership/Membership'
import OnlineGift from '../OnlineGift/OnlineGift'
import OurLocation from '../OurLocation/OurLocation'
import Addon from '../Addon/Addon'
import LocationDetails from '../JSONData/LocationDetails.json'
import servicesData from '../JSONData/ServiceDetails.json'
import Places from '../Places/Places'
import './Overlay.css'
import './OverlayResponsive.css'
import Terms from '../Terms&Conditions/Terms'
import Corefit from '../Corefit/Corefit'
import { Video } from '../Video/Video'

class Overlay extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      city: LocationDetails,
      servicesData: servicesData
    }
    this.handleAnimation = this.handleAnimation.bind(this)
    this.delayHandle = this.delayHandle.bind(this)
  }

  componentName() {
    switch (this.props.typeOfComponent) {
      case 'Booking':
        return <Booking hideOverlay={this.props.hideOverlay} servicesData={this.state.servicesData} services={this.props.serviceTypes} locationDetails={this.state.city} />
      case 'Membership':
        return <Membership hideOverlay={this.props.hideOverlay} memToggle={this.props.memToggle} servicesData={this.state.servicesData} services={this.props.serviceTypes} locationDetails={this.state.city} planDetails={this.props.planDetails} />
      case 'OnlineGift':
        return <OnlineGift hideOverlay={this.props.hideOverlay} servicesData={this.state.servicesData} services={this.props.serviceTypes} locationDetails={this.state.city} selectedGift={this.props.selectedGift} />
      case 'OurLocation':
        return <OurLocation hideOverlay={this.props.hideOverlay} selectedLocation={this.props.selectedLocation} />
      case 'Places':
        return <Places hideOverlay={this.props.hideOverlay} handleOverlay={this.props.handleOverlay} handleAnim={this.delayHandle} />
      case 'Terms':
        return <Terms hideOverlay={this.props.hideOverlay} handleOverlay={this.props.handleOverlay} terms={this.props.terms} />
      case 'Addon':
        return <Addon hideOverlay={this.props.hideOverlay} handleOverlay={this.props.handleOverlay} Addonplans={this.props.Addonplans} />
      case 'Corefit':
        return <Corefit hideOverlay={this.props.hideOverlay} handleOverlay={this.props.handleOverlay} />
      case 'VideoPlayer':
        return <Video hideOverlay={this.props.hideOverlay} />
      default:
        return null
    }
  }

  // hide background scroll
  componentDidMount() {
    const targetElement = document.querySelector('body')
    targetElement.style.overflow = 'hidden'
    // disableBodyScroll(targetElement)
    // disableBodyScroll('body')
    this.setState({ show: !this.state.show })
  }

  // reset background scroll
  componentWillUnmount() {
    // clearAllBodyScrollLocks()
    const targetElement = document.querySelector('body')
    targetElement.style.overflow = 'auto'
    this.setState({ show: !this.state.show })
  }

  delayHandle() {
    setTimeout(() => {
      this.setState({ show: !this.state.show })
      this.props.hideOverlay()
    }, 2500)
  }

  handleAnimation() {
    this.setState({ show: !this.state.show })
    setTimeout(() => { this.props.hideOverlay() }, 300)
  }

  render() {
    const component = this.componentName()
    // console.log('component', component)
    return (
      <Fade when={this.state.show}>
        <div id='overlay'>
          <div className={'overlay-block ' + this.props.size}>
            {this.props.typeOfComponent !== 'Places' ? (
              <div className={this.props.typeOfComponent === 'Corefit' ? 'overlay-body corefit-sel' : this.props.typeOfComponent === 'VideoPlayer' ? 'video-overlay-body' : 'overlay-body'}>
                <div className='close-img' data-testid='close' onClick={this.handleAnimation} />
                {component}
              </div>
            ) : component}
          </div>
        </div>
      </Fade>
    )
  }
}

export default Overlay
