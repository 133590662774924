import React, { Component } from 'react'
import './ToggleButton.css'

class ToggleButton extends Component {
  render () {
    // console.log(this.props)
    const toggleValue = this.props.toggleValue.split(',')
    return (
      <div className='toogle-btn'>
        <label className='switch' >
          <input type='checkbox' checked={this.props.checkedStatus} onClick={this.props.toggleClick} />
          <span className='slider round'><span className='self'>{toggleValue[0]}</span> <span className='gift'>{toggleValue[1]}</span></span>
        </label>
      </div>
    )
  }
}
export default ToggleButton
