import React from 'react'
import ReactDOM from 'react-dom'
// import 'bootstrap/dist/css/bootstrap.css'
import './index.css'
import { ToastContainer } from 'react-toastify'
import Routes from './Routes'
import * as serviceWorker from './serviceWorker'
window.isLaunched = 'false'
ReactDOM.render(
  <>
    <ToastContainer
      autoClose={3000}
      position='top-right'
      hideProgressBar
      newestOnTop
      closeOnClick
      rtl={false}
      pauseOnVisibilityChange={false}
      draggable={false}
      pauseOnHover={false} />
    <Routes />
  </>
, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
