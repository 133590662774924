import React from 'react'
import { withRouter } from 'react-router-dom'
import './CCAVResponseHandler.css'
class CCAVResponseHandler extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isPaymentSuccess: true,
      orderNo: '',
      referenceNo: '',
    }
  }


  componentDidMount() {
    // Access query parameters from the URL
    const params = new URLSearchParams(this.props.location.search);
    const id = params.get('id');
    const ref = params.get('ref');

    // Update state with the data parameter
    this.setState({ orderNo: id, referenceNo: ref });
  }


  render() {
    return (
      <div id='ccav-block'>
        {this.state.isPaymentSuccess ? (
          <div className='ccav-box'>
            <div className='ic-ccav-success' />
            <div className='ccav-succ-title'>Thank you for your order!</div>
            <div className='ccav-succ-sub-title'>The order confirmation email with details of your order has been sent to your email address.</div>
            <div className='ccav-ref-block'>
              <div className='ccav-order-title'>Your order is <span>{this.state.orderNo}</span></div>
              <div className='ccav-divider' />
              <div className='ccav-ref-title'>Your reference id <span>{this.state.referenceNo}</span></div>
            </div>
            <div className='ccav-go-back'>Please go back to <a href='/'>Home</a></div>
          </div>
        ) : (
          <div className='ccav-loader-box'>
            <img className='ccav-loader' src='/assets/images/ccavenue-loader.gif' alt='loader' />
            <div className='ccav-loader-title'>Processing payment. Please wait...</div>
          </div>
        )}
      </div>
    )
  }
}

export default withRouter(CCAVResponseHandler)
