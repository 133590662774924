import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'
// import 'react-datepicker/dist/react-datepicker.css'
import OverlayHead from '../OverlayHead/Overlayheader'
import ServiceDetails from './ServiceDetails/ServiceDetails'
import Datetime from 'react-datetime'
import 'react-datetime/css/react-datetime.css'
import moment from 'moment'
// import { fetchData } from '../../../API/ApiRequest.js'
import { firebase, validateEmail, cityDetails, ccavMerchantId, ccavRedirectURL, ccavCancelURL, getDefaultCity, disclaimers } from '../../../global'
import './Booking.css'
// import { getDate } from 'date-fns'
const db = firebase.firestore();
class Booking extends Component {
  constructor (props) {
    super(props)
    this.itemRender = (li, itemProps) => {
      let itemChildren
      // console.log('sdkvb<d',itemProps.dataItem['disabled'])
      // console.log('sdkvb<d',itemProps)
      if (itemProps.dataItem.disabled) {
        itemChildren = <span style={{ color: '#ccc', cursor: 'auto' }}>{li.props.children}</span>
      } else {
        itemChildren = <span>{li.props.children}</span>
      }

      return React.cloneElement(li, li.props, itemChildren)
    }
    this.state = {
      locationDetailsArr: cityDetails(),
      // servicesDataArr: this.props.servicesData,
      servicesDataArr: [],
      errMsg: '',
      mailMessage: '',
      mailStatus: '',
      addedAmount: 0,
      disablebtn: 'outer-btn',
      tempServiceCount: 1,
      defaultCityId: '',
      membershipCardNumber: '',
      formFields: {
        name: '',
        mobile: '',
        email: '',
        address: '',
        bookingDate: moment(new Date()).format('DD/MM/YYYY'),
        bookingTime: '',
        bookingCity: this.props.services ? this.props.services.city : getDefaultCity() === null ? '' : getDefaultCity().toLowerCase(),
        bookingLocation: '',
        bookingLocationId: '',
        // bookingLocation: this.props.services ? this.props.services.location.location : '',
        membershipCard: 'no',
        cardNumber: '',
        notes: ''
      },
      formErrors: {
        name: '',
        mobile: '',
        email: '',
        bookingDate: '',
        bookingTime: '',
        bookingCity: '',
        bookingLocation: '',
        membershipCard: ''
      },
      serviceCount: 1,
      serviceDetails: [
        {
          serviceTypes: [],
          serviceTimes: [],
          serviceId: this.props.services ? this.props.services.serviceId : '',
          serviceTypeId: this.props.services ? this.props.services.serviceTypeId : '',
          serviceDetail: {
            serviceName: this.props.services ? this.props.services.serviceName : '',
            serviceTypeName: this.props.services ? this.props.services.serviceTypeName : '',
            serviceTime: '',
            price: 0
          },
          Addons: [],
          serviceError: { serviceName: '', serviceTypeName: '', serviceTime: '', price: '' }
        }
      ], // Service details and error block
      locationList: [], // Location list based on city selection
      serviceNameList: [], // Service list based on city selection
      // serviceTypes: [], // Service type list based on service selection
      // serviceTimes: [], // Service time based on service Types
      serviceTypeIds: [], // service type id's based on location
      membershipCard: 'no',
      fromWhere: false,
      overlayHead: {
        title: 'BOOK APPOINTMENT',
        overlayDesc: 'We make it easy for you to begin your relaxation and wellness experience. Fill in your details and be ready to get pampered as soon as you arrive at our location.',
        imgClass: 'booking-register'
      },
      timeDetails: [
        { timeId: 11, time: '11:00 A.M' },
        { timeId: 12, time: '12:00 P.M' },
        { timeId: 13, time: '01:00 P.M' },
        { timeId: 14, time: '02:00 P.M' },
        { timeId: 15, time: '03:00 P.M' },
        { timeId: 16, time: '04:00 P.M' },
        { timeId: 17, time: '05:00 P.M' },
        { timeId: 18, time: '06:00 P.M' },
        { timeId: 19, time: '07:00 P.M' },
        { timeId: 20, time: '08:00 P.M' },
        { timeId: 21, time: '09:00 P.M' }
      ],
      serviceSlot: [],
      currentDate: true,
      totalOrderAmt: 0,
      Addonclass: '',
      branchEmail: '',
      disclaimerDetail: disclaimers()
    }

    this.addServices = this.addServices.bind(this)
    this.handleDropDownChange = this.handleDropDownChange.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this) // For update state when change the input
    this.membershipOnChange = this.membershipOnChange.bind(this) // Radio button onchange event
    this.handleServiceDropDownChange = this.handleServiceDropDownChange.bind(this)
    this.handleServiceRemoveEvent = this.handleServiceRemoveEvent.bind(this) // Remove service details
    this.handleBookingDetails = this.handleBookingDetails.bind(this) // Form Validation default
    this.handleEmptyFormCheck = this.handleEmptyFormCheck.bind(this)
    this.cleanServiceDropDownValues = this.cleanServiceDropDownValues.bind(this) // Clean service drop down value. Used for when click parent drop downs
    this.handleDateChange = this.handleDateChange.bind(this)
    this.handleEnter = this.handleEnter.bind(this)
    this.itemRender = this.itemRender.bind(this)
    // this.handleMembershipCard = this.handleMembershipCard.bind(this)
  }

  // to change booking date
  handleDateChange (objDate) {
    // if (!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(moment(objDate).format('DD/MM/YYYY'))) {
    //   console.log('invlid datw')
    //   // console.log('date', moment(objDate).format('DD/MM/YYYY'))
    //   return false
    // } else {
    // console.log('objdate', moment(new Date()).format('DD/MM/YYYY'))
    const calenderDate = moment(objDate).format('DD/MM/YYYY')
    const currentDate = moment(new Date()).format('DD/MM/YYYY')
    if (currentDate !== calenderDate) {
      this.setState({ currentDate: false })
    } else {
      this.setState({ currentDate: true })
    }
    const formFields = this.state.formFields
    const formErrors = this.state.formErrors
    formFields.bookingDate = moment(objDate).format('DD/MM/YYYY')
    formFields.bookingTime = ''
    formErrors.bookingDate = ''
    this.setState({ formFields, formErrors })
    // }
  }

  handleEnter (e) {
    // console.log('sdfdsf', e.which)
    if ((e.which || e.keyCode) === 13) { return true } else {
      return false
    }
  }
  // itemRender (li, itemProps){
  //   console.log('sdkvb<d')
  //   // const index = itemProps.index
  //   // const itemChildren = <span style={{ color: '#00F' }}>{li.props.children} {index}</span>
  //   // return React.cloneElement(li, li.props, itemChildren)
  // }

  componentDidMount () {
    const { locationDetailsArr } = this.state
    const { bookingCity } = this.state.formFields
    // console.log('time', new Date().getTime())
    var currentHour = parseInt(new Date().getHours())
    this.state.timeDetails.forEach((val, idx) => {
      if (parseInt(val.timeId) > currentHour) {
        this.state.serviceSlot.push(val)
      }
    })
    // Object.keys(value.serviceDetail).forEach(key => {
    //   if (key === 'price') {
    //     tempServiceArray[key] = '₹' + value.serviceDetail[key]
    //   } else {
    //     tempServiceArray[key] = value.serviceDetail[key]
    //   }
    // })
    // var ampm = (standaredTime < 12 || standaredTime === 24) ? 'AM' : 'PM'

    if (bookingCity !== '') { // validating cookie if expired
      const data = locationDetailsArr.filter((values) => values.city.toLowerCase() === bookingCity.toLowerCase())
      // to get location list based on city selection
      this.setState({ defaultCityId: data[0].cityId, locationList: data[0].locationList })

      // to get service name list based on city selection
      this.setState({ serviceNameList: data[0].serviceList })

      if (this.props.services) {
        // to get city details based on city selection
        // console.log('service type',this.state.serviceNameList)
        // to get service type list based on service name
        data[0].serviceList.forEach((val, idx) => {
          if (val.serviceName === this.props.services.serviceName) {
            const tempSerTypes = this.state.serviceDetails
            // console.log('service type',val.serviceTypesList )
            tempSerTypes[0].serviceTypes = val.serviceTypesList
            this.setState({ serviceDetails: tempSerTypes })
          }
        })

        // to get service time list based on service name
        this.state.serviceDetails[0].serviceTypes.forEach((val, idx) => {
          if (val.serviceTypeName === this.props.services.serviceTypeName) {
            const tempSerTimes = this.state.serviceDetails
            tempSerTimes[0].serviceTimes = val.serviceHours
            this.setState({ serviceDetails: tempSerTimes })
          }
        })

        // to load service details list based on city selection
        this.setState({ servicesDataArr: data[0].serviceList })
        this.setState({ Addonclass: 'addon hide' })
      }
    }
  }

  // Membership details submision.
  handleBookingDetails () {
    const { bookingDate, bookingTime, name, email, mobile, address, bookingCity, bookingLocation, notes, membershipCard } = this.state.formFields
    const { membershipCardNumber, totalOrderAmt, branchEmail } = this.state
    const formErrors = this.state.formErrors
    const currentDate = moment(new Date()).format('DD/MM/YYYY')

    this.setState({ disablebtn: 'outer-btn disablebtn' })

    if (this.handleEmptyFormCheck()) {
      // fields validation
      if (!(/^[a-zA-Z ]+$/.test(name))) {
        formErrors.name = (/^[a-zA-Z ]+$/.test(name)) ? '' : 'error'
        this.setState({ errMsg: 'Please enter your name' })
        this.setState({ disablebtn: 'outer-btn' })
        return
      }
      if (!validateEmail(email)) {
        formErrors.email = 'error'
        this.setState({ errMsg: 'Invalid email! Please enter your email' })
        this.setState({ disablebtn: 'outer-btn' })
        return
      }
      if (!(/^(\+(([0-9]){1,2})[-.])?((((([0-9]){2,3})[-.]){1,2}([0-9]{4,10}))|([0-9]{10}))$/.test(mobile))) {
        formErrors.mobile = 'error'
        this.setState({ errMsg: 'Invalid mobile number! Please enter your mobile number' })
        this.setState({ disablebtn: 'outer-btn' })
        return
      }
      if (!(/^\d{2}[./-]\d{2}[./-]\d{4}$/.test(bookingDate)) || (new Date(currentDate).getTime() > new Date(bookingDate).getTime())) {
      // if (!Date.now(bookingDate)) {
        formErrors.bookingDate = 'error'
        this.setState({ errMsg: 'Invalid booking date! Please choose correct booking date' })
        this.setState({ disablebtn: 'outer-btn' })
        return
      }
      if (membershipCard === 'yes') {
        formErrors.membershipCard = 'error'
        this.setState({ errMsg: 'Please enter your membership card number' })
        this.setState({ disablebtn: 'outer-btn' })
        return
      }

      // to get persons service array
      const ServiceArray = []
      let tempServiceArray = {}
      const servicearr = this.state.serviceDetails
      // servicearr['price'] = '₹' + value.serviceDetail[price]
      servicearr.forEach((value, index) => {
        // value.serviceDetail['price'] = '₹' + value.serviceDetail['price']
        // this.setState({ serviceDetails: servicearr })
        tempServiceArray = {}
        Object.keys(value.serviceDetail).forEach(key => {
          if (key === 'price') {
            tempServiceArray[key] = '₹' + value.serviceDetail[key]
          } else {
            tempServiceArray[key] = value.serviceDetail[key]
          }
        })
        ServiceArray.push(tempServiceArray)
      })
      // console.log('service array', ServiceArray)
      // return
      this.setState({ errMsg: '' })

      const currday = new Date()
      const orderId = currday.getTime().toString()

      const bookingDetails = {
        'Order Id': orderId,
        'No.of Person': ServiceArray.length,
        Name: name,
        'Mobile Number': mobile,
        'Email Id': email,
        Date: bookingDate,
        Time: bookingTime,
        City: bookingCity,
        Location: bookingLocation,
        BranchEmail: branchEmail,
        // 'BranchEmail': 'radhakrishnan.y@tringapps.com',
        TotalPrice: '₹' + totalOrderAmt.toString(),
        Address: (address === '' ? '-' : address),
        'Membership Card': (this.state.membershipCard === 'yes' ? 'YES' : 'NO'),
        'Membership Card Number': (membershipCardNumber === '' ? '-' : membershipCardNumber),
        Notes: (notes === '' ? '-' : notes),
        serviceDetails: ServiceArray,
        templateData: {
          to: email,
          subject: 'AromaThai Day Spa Service Booking and Order Id - ' + orderId,
          description: 'Thank you for booking a service at AromaThai Day Spa.'
        }
      }
      // console.log('Booking Details :', bookingDetails)
      sessionStorage.setItem('orderDetail', JSON.stringify(bookingDetails));
      sessionStorage.setItem('orderDetailType', 'BOOKING');

      window.localStorage.setItem('orderDetail', JSON.stringify(bookingDetails))
      window.localStorage.setItem('orderDetailType', 'BOOKING')
      db.collection('payment').doc(orderId).set({
        order_id: orderId,
        no_of_person: ServiceArray.length,
        name: name,
        mobile_number: mobile,
        createdAt: firebase.firestore.FieldValue.serverTimestamp(),
        email_id: email,
        date: bookingDate,
        time: bookingTime,
        city: bookingCity,
        location: bookingLocation,
        branch_email: branchEmail,
        total_price: '₹' + totalOrderAmt.toString(),
        address: (address === '' ? '-' : address),
        membership_card: (this.state.membershipCard === 'yes' ? 'YES' : 'NO'),
        membership_card_number: (membershipCardNumber === '' ? '-' : membershipCardNumber),
        notes: (notes === '' ? '-' : notes),
        service_details: ServiceArray,
        mailType: 'BOOKING',
        objMailData: bookingDetails
      })
        .then((docRef) => {
          console.log('Document Written', docRef)
          const orderAmount = parseFloat(this.state.totalOrderAmt)
          const orderKey = 'merchant_id=' + ccavMerchantId + '&order_id=' + orderId + '&currency=INR&amount=' + orderAmount + '&redirect_url=' + ccavRedirectURL + '&cancel_url=' + ccavCancelURL + '&language=EN'
          this.props.history.push('/ccavenue', { orderKey: orderKey, orderdetails: bookingDetails })
        })
        .catch((error) => {
          console.log('Error', error)
        })
      // to make querystring to get ccavenue payment page.
      // const orderAmount = parseFloat(this.state.totalOrderAmt)
      // const orderKey = 'merchant_id=' + ccavMerchantId + '&order_id=' + orderId + '&currency=INR&amount=' + orderAmount + '&redirect_url=' + ccavRedirectURL + '&cancel_url=' + ccavCancelURL + '&language=EN'
      // this.props.history.push('/ccavenue', { orderKey: orderKey })
    } else {
      this.setState({ disablebtn: 'outer-btn' })
    }
  }

  // to check all fields are entered or not.
  handleEmptyFormCheck () {
    let valid = true
    const formFields = this.state.formFields
    const errorFields = this.state.formErrors
    Object.keys(formFields).forEach(key => {
      const value = formFields[key]
      if (value === null || value.length <= 0) {
        if (Object.keys(this.state.formErrors).indexOf(key) >= 0) {
          errorFields[key] = 'error'
          valid = false
          this.setState({ errMsg: '*Please enter mandatory fields' })
        }
      }
    })
    if ((this.state.membershipCard === 'yes') && (this.state.membershipCardNumber.length <= 0)) {
      errorFields.membershipCard = 'error'
    }

    this.setState({ errorFields })
    this.state.serviceDetails.forEach(function (val, idx) {
      const errorFields = val.serviceError
      Object.keys(val.serviceDetail).forEach(key => {
        const value = val.serviceDetail[key]
        if (value.length <= 0) {
          errorFields[key] = 'error'
          valid = false
        }
      })
      return (val.serviceError = errorFields)
    })

    return valid
  }

  // Input change event
  handleInputChange (e) {
    const { name, value } = e.target
    const formErrors = this.state.formErrors
    if (name === 'name') formErrors.name = ''
    else if (name === 'mobile') formErrors.mobile = ''
    else if (name === 'email') formErrors.email = ''
    else if (name === 'bookingDate') formErrors.bookingDate = ''
    else if (name === 'bookingTime') formErrors.bookingTime = ''
    else if (name === 'cardNumber') formErrors.membershipCard = ''

    this.setState({ formErrors, errMsg: '' })
    const formfieldsState = this.state.formFields
    if (name === 'mobile') {
      if (!isNaN(value)) { formfieldsState[e.target.name] = e.target.value }
    } else {
      if (name === 'cardNumber') {
        if (!(/[^a-zA-Z0-9]/.test(value))) {
          this.setState({ membershipCardNumber: value })
        }
      }
      formfieldsState[e.target.name] = e.target.value
    }
    this.setState({ formfieldsState })
  }

  // Basic Details Dropdown changes.
  handleDropDownChange (event, objType) {
    const { locationDetailsArr } = this.state
    const updatedStates = this.handleDropdownState(event) // return the updated values from drop down
    this.setState({ updatedStates })
    const formError = this.state.formErrors
    formError[event.target.name] = ''
    this.setState({ formErrors: formError })
    const removedError = { ...this.state.formErrors }
    removedError[event.target.name] = ''
    this.setState({ removedError })
    if (objType === 'bookingCity') {
      // console.log('state', this.state)
      // to get city details based on city selection
      const data = locationDetailsArr.filter((values) => values.city === event.target.value.city)
      this.setState({ locationList: data[0].locationList })

      // Remove the drop down value when change the city drop down
      const formFields = this.state.formFields
      formFields.bookingCity = event.target.value.city
      formFields.bookingLocation = ''
      this.setState({ formFields: formFields, totalOrderAmt: 0 })
      this.cleanServiceDropDownValues()

      // to get location list based on city selection
      this.setState({ locationList: data[0].locationList })

      // to get service name list based on city selection
      this.setState({ serviceNameList: data[0].serviceList })

      // to clear the all services when we change the city
      this.setState({
        serviceDetails: [{
          serviceTypes: [],
          serviceTimes: [],
          serviceDetail: {
            serviceName: '',
            serviceTypeName: '',
            serviceTime: '',
            price: 0
          },
          serviceError: {
            serviceName: '',
            serviceTypeName: '',
            serviceTime: '',
            price: ''
          }
        }]
      })
      this.setState({ serviceCount: 1 })
    } else if (objType === 'bookingLocation') {
      const data = this.state.locationList.filter((values) => values.location === event.target.value.location)
      const formFields = this.state.formFields
      formFields.bookingLocation = data[0].location
      formFields.bookingLocationId = data[0].locationId

      this.setState({ formFields: formFields, branchEmail: data[0].email,totalOrderAmt:0 })
      this.cleanServiceDropDownValues()
      // to clear the all services when we change the location
      this.setState({
        serviceDetails: [{
          serviceTypes: [],
          serviceTimes: [],
          serviceDetail: {
            serviceName: '',
            serviceTypeName: '',
            serviceTime: '',
            price: 0
          },
          serviceError: {
            serviceName: '',
            serviceTypeName: '',
            serviceTime: '',
            price: ''
          }
        }]
      })
    } else if (objType === 'bookingTime') {
      const data = this.state.timeDetails.filter((values) => values.time === event.target.value.time)
      const formFields = this.state.formFields
      formFields.bookingTime = data[0].time
      this.setState({ formFields: formFields })
    }
  }

  // Clean service Drop down value
  cleanServiceDropDownValues () {
    const servicefields = [...this.state.serviceDetails]
    servicefields.forEach(function (val, idx) {
      Object.keys(val.serviceDetail).forEach(key => { val.serviceDetail[key] = '' })
    })
    this.setState({ servicefields })
  }

  // Service dropdown changes
  handleServiceDropDownChange (e, objType) {
    const { serviceNameList } = this.state
    // console.log(this.state)
    this.setState({ errMsg: '' })
    if (['serviceName', 'serviceTypeName', 'serviceTime', 'price'].includes(e.target.name)) {
      const targetAttr = (e.target.name === 'price') ? e.target.getAttribute('data-id') : e.target.props['data-id']
      const serviceDetails = [...this.state.serviceDetails]
      serviceDetails[targetAttr].serviceDetail[e.target.name] = (e.target.name === 'price') ? e.target.value : e.target.value[e.target.name]
      serviceDetails[targetAttr].serviceError[e.target.name] = ''

      if (objType === 'serviceName') {
        const data = serviceNameList.filter((values) => values.serviceName === e.target.value.serviceName)
        serviceDetails[targetAttr].serviceTypes = data[0].serviceTypesList
        if (e.target.value.serviceName === 'Body Masques') {
          this.setState({ Addonclass: 'addon ' })
          serviceDetails[targetAttr].Addons = data[0].Addonplans
        } else {
          this.setState({ Addonclass: 'addon hide' })
        }
        // console.log('jkdsnbgfd', data[0].serviceTypesList[0].serviceTypeName)
        // to reorder the service type when service name changed.
        serviceDetails[targetAttr].serviceTimes = []
        serviceDetails[targetAttr].serviceDetail.serviceTypeName = ''
        serviceDetails[targetAttr].serviceDetail.serviceTime = ''
        this.setState({ totalOrderAmt: this.state.totalOrderAmt - parseInt(serviceDetails[targetAttr].serviceDetail.price) })
        serviceDetails[targetAttr].serviceDetail.price = 0
        // this.setState({ totalOrderAmt: this.state.totalOrderAmt - this.state.addedAmount, addedAmount: 0 })
      } else if (objType === 'serviceTypeName') {
        const data = serviceDetails[targetAttr].serviceTypes.filter((values) => values.serviceTypeName === e.target.value.serviceTypeName)
        serviceDetails[targetAttr].serviceTimes = data[0].serviceHours
        serviceDetails[targetAttr].serviceDetail.serviceTime = ''
        this.setState({ totalOrderAmt: this.state.totalOrderAmt - parseInt(serviceDetails[targetAttr].serviceDetail.price) })
        serviceDetails[targetAttr].serviceDetail.price = 0
        // this.setState({ totalOrderAmt: this.state.totalOrderAmt - this.state.addedAmount, addedAmount: 0 })
      }
      if (objType === 'serviceTime') {
        var price = 0
        const data = this.state.serviceDetails[targetAttr].serviceTimes.filter((values) => values.serviceTime === e.target.value.serviceTime)
        // console.log('service count', this.state.serviceCount)
        // if (this.state.serviceCount !== this.state.tempServiceCount) {
        price = this.state.totalOrderAmt - serviceDetails[targetAttr].serviceDetail.price + parseInt(data[0].servicePrice)
        // this.setState({ tempServiceCount: this.state.serviceCount, addedAmount: data[0].servicePrice })
        // price = this.state.totalOrderAmt + parseInt(data[0].servicePrice)
        // } else {
        //   this.setState({ addedAmount: data[0].servicePrice })
        //   price = this.state.totalOrderAmt - parseInt(this.state.addedAmount) + parseInt(data[0].servicePrice)
        // }
        this.setState({ totalOrderAmt: price })
        serviceDetails[targetAttr].serviceDetail.price = data[0].servicePrice
        serviceDetails[targetAttr].serviceError.price = ''
      }
      this.setState({ serviceDetails })
    } else this.setState({ [e.target.name]: e.target.value.toUpperCase() })
  }

  // construct the object for drop down with this name
  handleDropdownState (event) {
    const formFields = this.state.formFields
    formFields[event.target.name] = event.target.value[event.target.name]
    return formFields[event.target.name]
  }

  // Remove service details
  handleServiceRemoveEvent (e) {
    // price to be removed while click on close button
    const serviceDetails = [...this.state.serviceDetails]
    this.setState({ totalOrderAmt: this.state.totalOrderAmt - parseInt(serviceDetails[e.target.getAttribute('data-id')].serviceDetail.price) })

    const prevStateValue = this.state.serviceDetails
    prevStateValue.splice(e.target.getAttribute('data-id'), 1)
    this.setState((prevState) => ({ serviceCount: this.state.serviceCount - 1 }))
    this.setState({ serviceDetails: prevStateValue })
  }

  // Radio button on change event
  membershipOnChange (event) { this.setState({ membershipCard: event.target.value, membershipCardNumber: '' }) }

  // Add Service details
  addServices (e) {
    this.setState((prevState) => ({
      serviceDetails: [...prevState.serviceDetails, {
        serviceTypes: [],
        serviceTimes: [],
        serviceDetail: {
          serviceName: '',
          serviceTypeName: '',
          serviceTime: '',
          price: 0
        },
        tempServiceCount: this.state.tempServiceCount + 1,
        serviceError: {
          serviceName: '',
          serviceTypeName: '',
          serviceTime: '',
          price: ''
        }

      }]
    }))
    this.setState((prevState) => ({ serviceCount: this.state.serviceCount + 1 }))
  }

  // this function returns service type based on corresponding service name
  // returnedServiceTypes (serviceName) { console.log(serviceName) }

  render () {
    var pastDays = Datetime.moment().subtract(1, 'day')
    var month = Datetime.moment().add(1, 'month')
    var validFuture = function (current) {
      // return current.isAfter(yesterday) && isBefore(month)
      return current.isAfter(pastDays) && current.isBefore(month)
    }
    // var validFuture = function (current) {
    //   return current.isAfter(moment()) // disabling future dates
    // }
    const { locationDetailsArr, locationList } = this.state
    const { name, email, mobile, address, bookingCity, bookingLocation, bookingLocationId, bookingTime } = this.state.formFields
    return (
      <div id='booking-container'>
        <div className='wrapper'>
          <OverlayHead overlaycontent={this.state.overlayHead} />
          <div className='form-container booking-form'>
            <div className='left-container'>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Name*</label>
                  <input type='text' name='name' data-testid = "name" className={'form-field ' + this.state.formErrors.name} autoComplete='off' value={name} placeholder='Enter your Name' onChange={this.handleInputChange} maxLength='50' />
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Email Id*</label>
                  <input type='text' name='email' data-testid = "email" className={'form-field ' + this.state.formErrors.email} autoComplete='off' value={email} onChange={this.handleInputChange} placeholder='Enter your Email Id' maxLength='50' />
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Mobile No*</label>
                  <input type='text' name='mobile' data-testid = "mobileNo" className={'form-field ' + this.state.formErrors.mobile} autoComplete='false' value={mobile} onChange={this.handleInputChange} placeholder='Enter your Mobile No' maxLength='10' />
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Address</label>
                  <input type='text' name='address' data-testid = "address" className='form-field' value={address} autoComplete='false' onChange={this.handleInputChange} placeholder='Enter your Address' maxLength='250' />
                </div>
              </div>
            </div>
            <div className='right-container'>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Date*</label>
                  <Datetime
                    dateFormat='DD/MM/YYYY'
                    timeFormat={false}
                    className={'date form-field ' + this.state.formErrors.bookingDate}
                    name='serviceDate'
                    defaultValue={new Date()}
                    isValidDate={validFuture}
                    closeOnSelect
                    closeOnTab
                    // onKeyDown = { => e.preventDefault()}}{this.handleEnter}
                    inputProps={{ placeholder: 'DD/MM/YYYY' }}
                    onChange={this.handleDateChange}
                  />
                  {/* <DatePicker className={'form-field ' + this.state.formErrors.bookingDate} selected={bookingDate} onChange={this.handleDateChange} minDate={new Date()} dateFormat='dd-MM-yyyy' /> */}
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups'>
                  <label className='field-caption'>Time*</label>
                  <DropDownList
                    data={this.state.currentDate ? this.state.serviceSlot : this.state.timeDetails}
                    textField='time'
                    name='bookingTime'
                    id='timeId'
                    className={this.state.formErrors.bookingTime}
                    value={{ timeId: null, time: (bookingTime !== '') ? bookingTime : 'Select Time' }}
                    defaultValue={{ timeId: null, time: (bookingTime !== '') ? bookingTime : 'Select Time' }}
                    onChange={(e) => this.handleDropDownChange(e, 'bookingTime')}
                  />
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups input-dropdown'>
                  <label className='field-caption'>City*</label>
                  <DropDownList
                    data={locationDetailsArr}
                    dataItemKey='cityId'
                    textField='city'
                    name='bookingCity'
                    id='city'
                    className={this.state.formErrors.bookingCity}
                    defaultValue={{ cityId: this.state.defaultCityId, city: (bookingCity !== '') ? bookingCity : '-Select City-' }}
                    onChange={(e) => this.handleDropDownChange(e, 'bookingCity')}
                  />
                </div>
              </div>
              <div className='input-container'>
                <div className='input-groups input-dropdown'>
                  <label className='field-caption'>Location*</label>
                  <DropDownList
                    data={locationList}
                    // value={this.state.locationList}
                    dataItemKey='locationId'
                    textField='location'
                    name='bookingLocation'
                    itemRender={this.itemRender}
                    className={this.state.formErrors.bookingLocation}
                    value={{ locationId: bookingLocationId, location: (bookingLocation !== '') ? bookingLocation : 'Select Location' }}
                    defaultValue={{ locationId: 0, location: (bookingLocation !== '') ? bookingLocation : 'Select Location' }}
                    onChange={(e) => this.handleDropDownChange(e, 'bookingLocation')}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='bottom full-width-sec'>
            <div className='input-container radio-sec'>
              <div className='input-groups radio-field'>
                <label className='field-caption'>Do you have Membership Card*</label>
                <div className='radio-input-section'>
                  <label className='radio-container' onClick={this.membershipOnChange}>Yes<input type='radio' checked={this.state.membershipCard === 'yes'} value='yes' name='membershipCard' className='form-field-radio' /> <span className='checkmark' /></label>
                  <label className='radio-container' onClick={this.membershipOnChange}>No<input type='radio' checked={this.state.membershipCard === 'no'} value='no' name='membershipCard' className='form-field-radio' /><span className='checkmark' /></label>
                </div>
              </div>
            </div>
            {(this.state.membershipCard === 'yes')
              ? <div className='input-container membership_card_sec'>
                <div className='input-groups'>
                  <label className='field-caption'>Enter Membership Card No*</label>
                  <input type='text' name='cardNumber' className={'mem-card form-field ' + this.state.formErrors.membershipCard} autoComplete='false' value={this.state.membershipCardNumber} onChange={this.handleInputChange} placeholder='Enter Your Membership Card No' maxLength='16' />
                </div>
              </div>
              : ''}
            <div className='input-container textarea-sec'>
              <div className='input-groups input-textarea'>
                <label className='field-caption note-filed'>Note/Request if any</label>
                <textarea name='notes' className='cls-notes' value={this.state.formFields.notes} autoComplete='false' onChange={this.handleInputChange} maxLength='500' />
              </div>
            </div>
          </div>
          <div className='service-container'>
            {this.state.serviceDetails.map((val, idx) => {
              const indexValue = idx + 1
              return <ServiceDetails currentState={this.state} currentService={val} handleServiceRemoveEvent={this.handleServiceRemoveEvent} handleServiceDropDownChange={this.handleServiceDropDownChange} service_id={indexValue} service_dataset_id={idx} />
            })}
            {/* to display total booking price */}
            <div className='tot-book-price'>Total Price <span>₹{this.state.totalOrderAmt}</span></div>

            {/* Add button for additional services */}
            <div className='add-service-sec'>
              {this.state.serviceCount < 5 ? <button className='add-service-btn' onClick={this.addServices}><span className='plus-icon' />ADD</button> : ''}
            </div>
            <hr className='service-lineBreak' />
            <div className='service-container-footer'>
              <div className='disclaimer'>
                <div className='disclaimer-header'>{this.state.disclaimerDetail[0].header}:</div>
                <div className='disclaimer-content'>
                  <ol style={{listStyle: 'number'}} type='1'>
                    {this.state.disclaimerDetail[0].content.map((data)=>(
                      <li>{data}</li>
                    ))}
                  </ol>
                </div>
                <div className='disclaimer-contactDetails'>
                  <div className='disclaimer-contactDetails-header'>Contact US:</div>
                  <div className='disclaimer-contactDetails-content'>
                    {locationList.map((data)=>(
                      <div className='disclaimer-branchDetails' key={data.locationId}>
                        <div className='branchLocation'>{data.location}</div>
                        <div className='branch-contactNumber'>Phone: {data.phoneNumber}</div>
                        <div className='branch-contactNumber'>Mobile: {data.mobileNumber}</div>
                      </div>
                    ))}
                  </div>
                
                </div>
              </div>
            </div>
          </div>

          {/* Error message */}
          <div className='cls-err-msg'>{this.state.errMsg}&nbsp;</div>
          <div className={this.state.mailStatus}>{this.state.mailMessage}&nbsp;</div>
          {/* Submit button */}
          <div className='form-submit-section'>
            <div className={this.state.disablebtn}>
              <div className='btn register-booking' data-testid = "submit" onClick={this.handleBookingDetails}>SUBMIT</div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default withRouter(Booking)
