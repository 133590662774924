import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import Header from '../Header/Header'
import Footer from '../../components/Footer/Footer'
import './termsandservice.css'

class TermsAndService extends Component {
  constructor (props) {
    super(props)
    this.state = {
        redirect:false,
        redirectTarget:'',
        componentName:''
    }
    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }

  handleOverlay (comp) { this.setState({ componentName: comp }) }

  hideOverlay () { this.setState({ componentName: '' }) }


  render () {
      if(this.state.redirect){
        return <Redirect to={this.state.redirectTarget} />
      }
    
    return (
      <div id='termsandservice'>
        <Header handleOverlay={this.handleOverlay} />
        <div  className="container">
            <div className="term-blk">
                <h3>Terms And Service</h3>
                <p>Welcome to <strong>www.aromathai.com.</strong> By using our site, you are agreeing to comply with and be bound by the following terms of use. Please review the following terms carefully. If you do not agree to these terms, you should not use this site.  The term <strong>“www.aromathai.com”</strong> or <strong>“us”</strong> or <strong>“we”</strong> or <strong>“our”</strong> refers to<strong>.www.aromathai.com</strong>, the owner of the Web site.  The term <strong>“you”</strong> refers to the user or viewer of our Web Site.</p>
                <p><h4>ACCEPTANCE OF AGREEMENT:</h4>You agree to the terms and conditions outlined in this Terms of Use Agreement ("Agreement") with respect to our site (the "Site"). This Agreement constitutes the entire and only agreement between us and you, and supersedes all prior or contemporaneous agreements, representations, warranties and understandings with respect to the Site, the content, products or services provided by or through the Site, and the subject matter of this Agreement. This Agreement may be amended at any time by us from time to time without specific notice to you. The latest Agreement will be posted on the Site, and you should review this Agreement prior to using the Site.</p>
                <p><h4>COPYRIGHT:</h4>The content, organization, graphics, design, compilation, magnetic translation, digital conversion and other matters related to the Site are protected under applicable copyrights, trademarks and other proprietary (including but not limited to intellectual property) rights. The copying, redistribution, use or publication by you of any such matters or any part of the Site (except as outlined below in "Permitted Uses") is strictly prohibited. You do not acquire ownership rights to any content, document or other materials viewed through the Site. The posting of information or materials on the Site does not constitute a waiver of any right in such information and materials.  Some of the content on the site is the copyrighted work of third parties and may not be distributed in any form without the express written permission of the creator.</p>
                <p><h4>LIMITATION OF LIABILITY</h4>To the maximum extent permitted by any law, <strong>www.aromathai.com</strong> specifically excludes any and all liability for any direct, indirect, special, incidental or consequential damages arising out of or in any way connected with the access to and use of, availability of or inability to access to and use of this website, or the information, products and services obtained through this website, the submission of any personal information, including, but not limited to, bank and credit card details, the performance or non-performance by <strong>www.aromathai.com</strong>, or any unauthorized access or breach of security into this website through the internet or otherwise arising, whether based on contract, strict liability, tort (including negligence), product liability or otherwise even if <strong>www.aromathai.com</strong> or its agents or employees have been advised of the possibility of such damages.</p>
                <p><h4>ACKNOWLEDGMENT</h4>The disclaimer and limited liability of <strong>www.aromathai</strong>.com as set out in this Agreement are fundamental elements of the basis of the bargain between you and us, and you agree that we would not otherwise be able to offer the products and services on its present basis without such limitations. This clause shall survive any termination of this Agreement.</p>
                <p><h4>FORCE MAJEURE</h4> <strong>www.aromathai.com</strong> shall not be liable for damages of any kind arising out of any failure or delay by <strong>www.aromathai.com</strong> in performing any or all of our obligations contained herein or in respect of any product or service if the failure or delay is due to circumstances or cause beyond our reasonable control.</p>
                <p><h4>CANCELLATION OF SERVICE</h4><strong>www.aromathai.com</strong> may without any liability to you whatsoever, at any time and at its sole discretion and without cause or notice, terminate your account or restrict your access to this website or refuse to give effect to any booking or reservation requested by you without being required to give any reasons or advance notice to you.</p>
                <p><h4>INDEMNITY</h4>You shall indemnify and keep <strong>www.aromathai.com</strong> indemnified against all claims, demands, actions, proceedings made or brought against <strong>www.aromathai.com</strong> arising from your use of this website and/or any breach of any term or condition by you.</p>
                <p><h4>LINKS TO THIRD PARTIES</h4>This website may contain hyperlinks to websites operated by parties other than <strong>www.aromathai.com</strong>. The inclusion of hyperlinks to such websites does not imply <strong>www.aromathai.com’s</strong> endorsement of such websites nor any association with their operators. <strong>www.aromathai.com</strong> is not responsible for the contents of other websites.</p>
                <p><h4>VARIATIONS</h4>a) <strong>www.aromathai.com</strong> may at any time vary these terms and conditions of use (including without limitation <strong>www.aromathai.com’s</strong> conditions of purchase as set out below) with or without notice to you.<p>b) You acknowledge that your continued use of the website and services provided herein after the variations of these terms and conditions of use shall constitute your acceptance of the same with immediate effect.</p><p>c) Please check this website regularly for such variations.</p></p>
                <p><h4>SEVERABILITY</h4>Any provision of this Agreement which is invalid, prohibited or unenforceable in any jurisdiction shall:</p>
                <p>i. Be ineffective in that jurisdiction to the extent of the invalidity, prohibition or unenforceability;</p>
                <p>ii. Not invalidate the remaining provisions of this Agreement; and</p>
                <p>iii. Not affect the validity of that provision in any other jurisdiction.</p>
                <p><h4>RELATIONSHIP BETWEEN THE PARTIES</h4>No joint venture, partnership, employment or agency relationship exists between you and corefitplus.com as a result of this Agreement or your use of this website.</p>
                <p><h4>USE OF INFORMATION:</h4>We reserve the right, and you authorize us, to the use and assignment of all information regarding Site uses by you and all information provided by you in any manner consistent with our Privacy Policy. All remarks, suggestions, ideas, graphics, or other information communicated by you to us (collectively, a "Submission") will forever be our property. We will not be required to treat any Submission as confidential, and will not be liable for any ideas (including without limitation, product, service or advertising ideas) and will not incur any liability as a result of any similarities that may appear in our future products, services or operations. Without limitation, we will have exclusive ownership of all present and future existing rights to the Submission of every kind and nature everywhere. We will be entitled to use the Submission for any commercial or other purpose whatsoever, without compensation to you or any other person sending the Submission. You acknowledge that you are responsible for whatever material you submit, and you, not us, have full responsibility for the message, including its legality, reliability, appropriateness, originality, and copyright.</p>
                <p><h4>THIRD-PARTY SERVICES:</h4>We may allow access to or advertise certain third-party products or service providers ("Merchants") from which you may purchase certain goods or services. You understand that we do not operate or control the products or services offered by Merchants. Merchants are responsible for all aspects of order processing, fulfilment, billing and customer service. We are not a party to the transactions entered into between you and Merchants. You agree that use of or purchase from such Merchants is AT YOUR SOLE RISK AND IS WITHOUT WARRANTIES OF ANY KIND BY US, EXPRESSED, IMPLIED OR OTHERWISE INCLUDING WARRANTIES OF TITLE, FITNESS FOR PURPOSE, MERCHANTABILITY OR NON-INFRINGEMENT. UNDER NO CIRCUMSTANCES ARE WE LIABLE FOR ANY DAMAGES ARISING FROM THE TRANSACTIONS BETWEEN YOU AND MERCHANTS OR FOR ANY INFORMATION APPEARING ON MERCHANT SITES OR ANY OTHER SITE LINKED TO OUR SITE.</p>
                <p><h4>THIRD-PARTY MERCHANT POLICIES:</h4>All rules, policies (including privacy policies) and operating procedures of Merchants will apply to you while on any Merchant sites. We are not responsible for information provided by you to Merchants. We and the Merchants are independent contractors and neither party has authority to make any representations or commitments on behalf of the other.</p>
                <p><h4>PRIVACY POLICY:</h4>Our Privacy Policy, as it may change from time to time, is a part of this Agreement.  You must review this Privacy Policy by clicking on this link.</p>
                <p><h4>MEMBERSHIP:</h4>You may only make bookings for or purchase of products and/or services through this website subject to your registration and our acceptance of you as a registered member of the website. Membership is not transferable but a registered member may make bookings on behalf of non-member(s).</p>
                <p><h4>PAYMENTS:</h4>You represent and warrant that if you are purchasing something from us or from Merchants that (i) any credit information you supply is true and complete, (ii) charges incurred by you will be honored by your credit card company, and (iii) you will pay the charges incurred by you at the posted prices, including any applicable taxes.</p>
                <p><h4>LINKS TO OTHER WEBSITES:</h4>The Site contains links to other Web sites. We are not responsible for the content, accuracy or opinions expressed in such Websites, and such Websites are not investigated, monitored or checked for accuracy or completeness by us. Inclusion of any linked Web site on our Site does not imply approval or endorsement of the linked Web site by us. If you decide to leave our Site and access these third-party sites, you do so at your own risk.</p>
                <p><h4>TERMINATION:</h4>The obligations and liabilities of the parties incurred prior to the termination date shall survive the termination of this agreement for all purposes.<p>These Terms of Service are effective unless and until terminated by either you or us. You may terminate these Terms of Service at any time by notifying us that you no longer wish to use our Services, or when you cease using our site.</p><p>If in our sole judgment you fail, or we suspect that you have failed, to comply with any term or provision of these Terms of Service, we also may terminate this agreement at any time without notice and you will remain liable for all amounts due up to and including the date of termination; and/or accordingly may deny you access to our Services (or any part thereof).</p></p>
                <p><h4>CHANGES TO TERMS OF SERVICE:</h4>You can review the most current version of the Terms of Service at any time on this page. We reserve the right, at our sole discretion, to update, change or replace any part of these Terms of Service by posting updates and changes to our website. It is your responsibility to check our website periodically for changes. Your continued use of or access to our website or the Service following the posting of any changes to these Terms of Service constitutes acceptance of those changes.</p>
                <p><h4>CONTACT INFORMATION:</h4>Questions about the Terms of Service should be sent to us at  <strong>info@aromathai.net</strong></p>
            </div>
         
        </div>
        {<Footer />}
      </div>
    )
  }
}

export default TermsAndService
