import React, { Component } from 'react'
import CityAddress from './CityAddress/CityAddress'
import '../OverlayHead/Overlayheader.css'
import './OurLocation.css'
import '../Booking/Booking.css'
class AccordionSection extends Component {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      class: 'section',
      sectionHeight: '',
      lastTargetElement: ''
    }
    this.handleClick = this.handleClick.bind(this)
    this.delayState = this.delayState.bind(this)
  }
  handleClick (e) {
    const targetelement = e.target
    const targetElementHeight = targetelement.classList.contains('sectionhead') ? targetelement.nextSibling.querySelector('#address_panel').clientHeight : targetelement.parentElement.nextSibling.querySelector('#address_panel').clientHeight
    // var a = document.querySelector('.open')
    // if ((a !== null)) {
    //   a.querySelector('#service_body').style.height = '0px'
    //   a.classList.remove('open')
    // }
    // // console.log('taersadijknbf', targetelement.parentElement.classList.contains('open'))
    
    // if ((this.state.lastTargetElement === targetelement.parentElement)) {
    //   // console.log('class list added')
    //   this.setState({ lastTargetElement: '' })
    // } else {
    //   targetelement.parentElement.classList.add('open')
    //   console.log('sdjkncj', targetelement.parentElement)
    //   targetelement.parentElement.querySelector('#service_body').style.height = targetElementHeight + 'px'
    //   // this.delayState()
    //   // console.log('classlist added')
    //   this.setState({ lastTargetElement: targetelement.parentElement })
    // }

    if (this.state.open) {
      this.setState({
        open: false,
        class: 'section',
        sectionHeight: '0px'
      })
    } else {
      this.setState({
        open: true,
        class: 'section open',
        sectionHeight: targetElementHeight
      })
    }
  }

  componentDidMount () {
    // console.log(document.getElementById('address_panel').clientHeight)
    if (this.props.selectedLocation) {
      this.setState({
        open: true,
        class: 'section open'
        // sectionHeight : document.getElementById('address_panel').clientHeight
      })
      this.delayState() // update the client height for selected element
    }
  }
  delayState () {
    setTimeout(() => {
      let selectedParent = document.querySelector('.open')
      this.setState({
        sectionHeight: selectedParent.querySelector('#address_panel').clientHeight
      })
    }, 500)
  }

  render () {
    const dynamicHeight = {
      height: this.state.sectionHeight

    }
    // console.log(this.state)
    return (

      <div className={this.state.class}>
        <div className='sectionhead' onClick={this.handleClick}>{this.props.title} <span className='expand-icon' /></div>
        <div className='sectionbody' style={dynamicHeight} id='service_body'>
          <div id='address_panel'>
            <CityAddress location={this.props.location} />
          </div>
        </div>
      </div>
    )
  }
}
export default AccordionSection
