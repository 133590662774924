import React from 'react'
import { ccavAccessCode, baseURL } from '../../global'

import './CCAvenue.css'

class CCAvenue extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      accessCode: ccavAccessCode,
    }
  }

  // Below code to submit and show ccavenue billing page
  componentDidMount() {
    fetch(baseURL + '/ccavenue', {
      method: 'POST',
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({ orderKey: this.props.location.state.orderKey}),
    }).then(response => response.text()).then(
      (data) => {
        this.setState({ encRequest: data });
        document.getElementById('nonseamless').submit();
      })
      .catch(error => {
        console.error('Fetch error:', error);
      });
    
  }


  render() {
    return (
      <form id='nonseamless' method='post' name='redirect' action='https://secure.ccavenue.com/transaction/transaction.do?command=initiateTransaction'>
        <input type='hidden' id='encRequest' name='encRequest' value={this.state.encRequest} />
        <input type='hidden' id='access_code' name='access_code' value={this.state.accessCode} />
      </form>
    )
  }
}


export default CCAvenue
