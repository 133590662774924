import React, { Component } from 'react'
// component

// css
import './Awaited.css'

class Awaited extends Component {
 
  render () {
    return (
      <div>
        <div id='awaited'>
          <div>
            <div className='ccav-succ-title'>Thank you for your order!</div>
            <div className='ccav-succ-title'>Your Bank is taking time to respond. Payment inprogress</div>
            <div className='ccav-succ-sub-title'>The order confirmation email with details of your order will be sent to your email address if your payment is success.</div>
          </div>
        </div>

      </div>
    )
  }
}

export default Awaited
