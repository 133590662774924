import React, { Component } from 'react'
import OverlayHead from '../OverlayHead/Overlayheader'
import CitySelection from '../../Reusable/CitySelection/CitySelection'
import '../OverlayHead/Overlayheader.css'
import './Places.css'
import './PlacesResponsive.css'
import '../Booking/Booking.css'

class Places extends Component {
  constructor (props) {
    super(props)
    this.state = {
      overlayHead: {
        title: 'SELECT YOUR CITY',
        overlayDesc: '',
        imgClass: 'our-location'
      },
      defLocation: ''
    }
    this.setLocation = this.setLocation.bind(this)
  }

  setLocation (comp, location) {
    var now = new Date()
    var name = 'cityName'
    this.setState({ defLocation: 'Thank you for choosing!! Your default city is ' + location })
    // now.setTime(now.getTime() + (60 * 1000))
    now.setDate(now.getDate() + 1)
    var expires = '; expires=' + now.toGMTString()
    document.cookie = name + '=' + location + expires + '; path=/'
    // document.cookie = 'cityName=' + location + ';' now.toUTCString()
    // document.cookie = 'expires=' + now.toUTCString() + ';'
    // window.localStorage.setItem('cityName', location)
    this.props.hideOverlay()
    // this.props.handleAnim()
  }

  render () {
    return (
      <div className='overlay-body city-sel'>
        <div id='places-container'>
          <div className='wrapper'>
            {/* <div className='send-noti suc-response'> {this.state.defLocation}&nbsp;</div> */}
            <OverlayHead overlaycontent={this.state.overlayHead} />
            <CitySelection handleOverlay={this.setLocation} />
          </div>
        </div>
      </div>
    )
  }
}
export default Places
