import React, { Component } from 'react'
// component
import QuickMenu from '../Reusable/QuickMenu/QuickMenu'
import Header from '../Header/Header'
import OverlayLayer from '../OverlayComponents/Overlay/Overlay'
import Fade from 'react-reveal/Fade'
// import Zoom from 'react-reveal/Zoom'
import Footer from '../../components/Footer/Footer'
import { getDefaultCity } from '../../global.js'
// css
import './GiftVoucher.css'
import './GiftVoucherResponsive.css'

class GiftVoucher extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedGift: 'service',
      terms: ['Please present an online generated voucher or printed copy of the same with an order ID at the time of redemption.',
        'This voucher is for one-time use only, It cannot be exchanged with cash or any other services.',
        'This voucher is transferable. ',
        'This voucher is valid up to 3 months from date of issue.',
        'Gift vouchers by service can only be redeemed at the branch they have been selected.',
        'Vouchers that are purchased for Kochi can only be redeemed in the respective branches.'
      ],
      bannerItem: {
        imageUrl: '/assets/images/gift_banner.jpg',
        title: 'Gift voucher',
        description: ''
      }
    }
    this.handleOverlay = this.handleOverlay.bind(this)
    this.handleCorefitOverlay = this.handleCorefitOverlay.bind(this)
    this.hideOverlay = this.hideOverlay.bind(this)
  }

  handleCorefitOverlay () { this.setState({ componentName: 'Corefit' }) }

  handleOverlay (comp, giftType) {
    // console.log('gift', giftType)
    this.setState({ componentName: comp, selectedGift: giftType })
  }

  hideOverlay () { this.setState({ componentName: '' }) }

  componentDidMount () {
    document.body.scrollTop = 0 // For Safari
    document.documentElement.scrollTop = 0 // For Chrome
    window.localStorage.setItem('pathName', 'GV')
    // if (window.localStorage.getItem('cityName') === null) {
    //   this.handleOverlay('Places')
    // }
    if (getDefaultCity() === null) {
      this.handleOverlay('Places')
    }
  }

  render () {
    const componentOpen = this.state.componentName === 'OnlineGift'
      ? <OverlayLayer typeOfComponent='OnlineGift' hideOverlay={this.hideOverlay} size='lg' selectedGift={this.state.selectedGift} />
      : this.state.componentName === 'Membership'
        ? <OverlayLayer typeOfComponent='Membership' hideOverlay={this.hideOverlay} size='lg' />
        : this.state.componentName === 'Places'
          ? <OverlayLayer typeOfComponent='Places' hideOverlay={this.hideOverlay} size='lg' />
          : this.state.componentName === 'Terms'
            ? <OverlayLayer typeOfComponent='Terms' hideOverlay={this.hideOverlay} terms={this.state.terms} size='lg' />
            : this.state.componentName === 'Corefit'
              ? <OverlayLayer typeOfComponent='Corefit' hideOverlay={this.hideOverlay} />
              : ''

    return (
      <div>
        {componentOpen}
        <Header handleCorefitOverlay={this.handleCorefitOverlay} />
        <div id='giftvoucher'>
          <QuickMenu handleOverlay={() => this.handleOverlay('Membership')} />
          <div className='banner'>
            <Fade bottom distance='30px'>
              <div className='banner-card' />
            </Fade>
          </div>
          <div className='gift-banner'>
            <div className='gift-block'>

              <div className='gift-cup-block'>
                <div className='ic-bag' />
                {/* <Zoom delay={100} duration={1500}> */}
                <div className='ic-divider' />
                {/* </Zoom> */}
                <Fade bottom distance='30px'>
                  <div className='ic-title'>Online Gift</div>
                </Fade>
                <Fade bottom distance='30px' delay={300}>
                  <div className='ic-desc'>A gift of wellness is valued by everyone. It is the perfect way to express gratitude, love and care. The vouchers are specially customized as per your gifting requirement and come along with a personalized message.</div>
                </Fade>
              </div>
            </div>
            <div className='gift-card-block' >
              {/* <Zoom duration={1500}> */}
              <div className='gift-service-card' data-testid = "giftServiceCard" onClick={() => this.handleOverlay('OnlineGift', 'service')} />
              {/* </Zoom> */}
              {/* <Zoom delay={200} duration={1500}> */}
              <div className='gift-voucher-card' data-testid = "giftVoucherCard" onClick={() => this.handleOverlay('OnlineGift', 'voucher')} />
              {/* </Zoom> */}
            </div>
            <div className='terms-cond' data-testid = "termsCondition" onClick={() => this.handleOverlay('Terms')}>*Terms and Conditions</div>
            {/* <Fade left delay={100} duration={2000}> */}
            <div className='cactus-back-left' />
            {/* </Fade> */}
            {/* <Fade bottom delay={100}> */}
            <div className='cactus-back-right' />
            {/* </Fade> */}
          </div>
        </div>
        <Footer />
      </div>
    )
  }
}

export default GiftVoucher
