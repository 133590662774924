import React, { Component } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
// import '@progress/kendo-theme-default/dist/all.css'

class SelfMembership extends Component {
  constructor (props) {
    super(props)
    this.state = {
      planPrice: ''
    }
  }
  componentDidMount () {
    this.setState({ planPrice: this.props.planPrice ? this.props.planPrice : '' })
    // console.log('subdfh',this.props)
  }
  render () {
    let { planPrice } = this.state
    return (
      <div className='form-container booking-form'>
        <div className='left-container'>
          <div className='input-container'>
            <div className='input-groups'>
              <label className='field-caption'>Name*</label>
              <input type='text' name='name' ref='name' autoComplete='off' onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.name} placeholder='Enter your Name' maxLength={50} required='' />
            </div>
          </div>
          <div className='input-container'>
            <div className='input-groups'>
              <label className='field-caption'>Mobile No*</label>
              <input type='text' name='mobileNo' ref='mobileNo' autoComplete='off' value={this.props.isNumber} onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.mobileNo} placeholder='Enter your Mobile No' maxLength={10} />
            </div>
          </div>
          <div className='input-container'>
            <div className='input-groups'>
              <label className='field-caption'>Email Id*</label>
              <input type='text' name='email' ref='email' autoComplete='off' onChange={this.props.handleInputChange} className={'form-field ' + this.props.currentState.formErrors.email} placeholder='Enter your Email Id' maxLength={50} />
            </div>
          </div>
        </div>
        <div className='right-container'>
          <div className='input-container'>
            <div className='input-groups input-dropdown'>
              <label className='field-caption'>Plan*</label>
              <DropDownList
                data={this.props.currentState.plans}
                dataItemKey='planId'
                name='plan'
                textField='plan'
                className={this.props.currentState.formErrors.plan}
                defaultValue={{ planId: this.props.currentState.planId, plan: (planPrice !== '') ? this.props.currentState.planPrice : 'Select Plan' }}
                onChange={(e) => this.props.handleDropDownChange(e, 'plan')}
              />
            </div>
          </div>
          <div className='input-container'>
            <div className='input-groups input-dropdown'>
              <label className='field-caption'>City*</label>
              <DropDownList
                data={this.props.currentState.cityDetails}
                dataItemKey='cityId'
                name='city'
                textField='city'
                // className={this.props.currentState.formErrors.city}
                defaultValue={{ cityId: this.props.currentState.defaultCityId, city: this.props.currentState.defaultCity !== '' ? this.props.currentState.defaultCity : '-Select City-' }}
                onChange={(e) => this.props.handleDropDownChange(e, 'city')}
              />
            </div>
          </div>
          <div className='input-container'>
            <div className='input-groups input-dropdown'>
              <label className='field-caption'>Location*</label>
              <DropDownList
                data={this.props.currentState.locationDetails}
                dataItemKey='locationId'
                textField='location'
                itemRender={this.props.itemRender}
                name='location'
                className={this.props.currentState.formErrors.location}
                value={{ locationId: this.props.currentState.formfields.locationId, location: (this.props.currentState.formfields.location !== '') ? this.props.currentState.formfields.location : 'Select Location' }}
                defaultValue={{ locationId: null, location: 'Select Location' }}
                onChange={(e) => this.props.handleDropDownChange(e, 'location')}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
export default SelfMembership
