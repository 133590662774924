/* Below fetchData method reference site - https://github.com/github/fetch */
import { baseURL } from "../global"

export function fetchData (reqBody, reqRoute) {
  // console.log('req body', reqBody)
  return fetch (baseURL + reqRoute, {
    method: 'POST',
    headers: {
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(reqBody)
  }).then(data => { return parseJSON(data) })
    .catch(error => { return error })
}

function parseJSON (response) {
  return response.json()
}
